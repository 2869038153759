<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'

export default {
  name: 'dictionaryChildren',
  data() {
    return {
      ...api.command.getState(),
      dataCode: null,
      tableData: []
    }
  },
  mounted() {
    this.dataCode = this.$route.query.code
    this.dataCode && this.handleGetDataINit()
  },
  methods: {
    handleGetDataINit(code = this.dataCode) {
      getAction('/api/dict/item/list?dictCode=' + code).then(res => {
        this.tableData = res.data
      })
    },
    getHeader() {
      return [
        {
          name: '字典名称',
          type: 'input',
          key: 'name'
        },
        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-90',
          isId: true
          //   sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'label',
          title: '字典标签',
          type: 'lt-100'
          //   sorter: (a, b) => a.label.length - b.label.length
        },
        {
          dataIndex: 'value',
          title: '字典键值',
          type: 'lt-100'
        },
        {
          dataIndex: 'dictCode',
          title: '所属字典',
          type: 'lt-100'
        },
        {
          dataIndex: 'treeSort',
          title: '排序号',
          width: 60,
          align: 'center'
          //   sorter: (a, b) => a.treeSort - b.treeSort
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  apiTool.showModal({
                    form: this.getCreateForm(),
                    formData: { ...records },
                    title: '编辑',
                    success: ({ data, setHidden }) => {
                      api.command.edit
                        .call(this, {
                          url: '/dict/item',
                          params: {
                            ...data
                          },
                          isPost: false
                        })
                        .then(result => {
                          this.handleGetDataINit()
                          setHidden()
                        })
                    }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/dict/item/${records.id}`,
                    isPost: false
                  })
                  setTimeout(e => {
                    this.handleGetDataINit()
                  }, 100)
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            apiTool.showModal({
              form: this.getCreateForm(),
              title: '添加',
              success: ({ data, setHidden }) => {
                let obj = {
                  ...data,
                  dictCode: this.dataCode
                }
                postAction('/dict/item', {
                  ...obj
                }).then(res => {
                  if (res.code == 200) {
                    this.handleGetDataINit()
                    setHidden()
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              }
            })
          }
        }
      ]
    },
    getCreateForm() {
      return [
        {
          name: '字典标签',
          type: 'input',
          key: 'label',
          rules: [
            {
              required: true,
              message: '请输入字典标签'
            }
          ]
        },
        {
          name: '字典键值',
          type: 'input',
          key: 'value',
          rules: [
            {
              required: true,
              message: '请输入字典键值'
            }
          ]
        },
        {
          name: '排序值',
          type: 'inputNumber',
          key: 'treeSort',
          rules: [
            {
              required: true,
              type: 'number',
              message: '请输入排序值'
            }
          ]
        },
        {
          name: '描述',
          type: 'input',
          key: 'description'
        },
        {
          name: '备注',
          type: 'textArea',
          key: 'remark'
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        // headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.tableData}
        filterRightButtonKey={['setting']}
        showPagination={false}
      />
    )
  }
}
</script>
